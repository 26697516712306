import { graphql } from "gatsby";
import React from "react";
import { usePageContext } from "../components/context/PageContext";
import { Layout } from "../components/layout/Layout";
import { ServicePageQuery } from "../../graphql-types";
import { PageImageHeader } from "../components/layout/PageImageHeader";
import { ContentSection } from "../components/section/ContentSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, library } from "@fortawesome/fontawesome-svg-core";
import BlockContent from "@sanity/block-content-to-react";
import {
  faBackpack,
  faBed,
  faBedEmpty,
  faBuilding,
  faBus,
  faCalendar,
  faCamera,
  faCampground,
  faCar,
  faConciergeBell,
  faGlassChampagne,
  faGlassCheers,
  faHeart,
  faHotel,
  faLeaf,
  faMapMarkedAlt,
  faMeat,
  faShoppingBag,
  faSunCloud,
  faUtensils,
} from "@fortawesome/pro-light-svg-icons";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "../styles/pages/services.module.scss";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";
import { SEO } from "../components/seo/seo";
import { ProBlocks } from "../components/blocksContent/ProBlocks";
import { HomeBlocks } from "../components/blocksContent/HomeBlocks";

interface Props {
  data: ServicePageQuery;
  location: any;
}

const ServicePage: React.FC<Props> = ({ data, location }) => {
  const { lang } = usePageContext();
  const { sanityServicePage } = data;

  return (
    <>
      <SEO rawMeta={sanityServicePage?._rawMetadata} />
      <PageImageHeader
        image={sanityServicePage?.data?.bgImage?.image?.asset?.gatsbyImageData}
        alt={sanityServicePage?.data?.bgImage?._rawAlt?.[lang]}
        title={sanityServicePage?.data?._rawTitle?.[lang]}
      />
      <BreadCrumb
        label={sanityServicePage?.data?._rawTitleBreadcrumb?.[lang]}
      />
      <ContentSection
        title={sanityServicePage?.data?._rawSubtitle?.[lang]}
        blocks={sanityServicePage?.data?._rawDescription?.[lang]}
      />
      {/* {sanityServicePage?.data.} */}
      <div className={styles.servicesWrapper}>
        {sanityServicePage?.data?.serviceItems?.map(item => (
          <div key={item?._key}>
            <FontAwesomeIcon
              className={styles.icon}
              icon={["fal", item?.icon as IconName]}
              fontSize={34}
            />
            <h3>{item?._rawTitle?.[lang]}</h3>
            <HomeBlocks blocks={item?._rawContent?.[lang]} />
          </div>
        ))}
      </div>
      <div className={styles.teamWrapper}>
        <div className={styles.teamIntro}>
          <h2>{sanityServicePage?.data?._rawTeamTitle?.[lang]}</h2>
          <BlockContent
            blocks={sanityServicePage?.data?._rawTeamDescription?.[lang]}
          />
        </div>

        <div className={styles.membersWrapper}>
          {sanityServicePage?.data?.teamMembers?.map(item => (
            <div className={styles.memberContainer} key={item?._key}>
              <div className={styles.imageContainer}>
                <GatsbyImage
                  image={item?.image?.asset?.gatsbyImageData}
                  alt={"tours"}
                  className={styles.image}
                  objectFit={"cover"}
                />
              </div>
              <h2>{item?.firstname}</h2>
              <h2 className={styles.uppercase}>{item?.name}</h2>
              <BlockContent blocks={item?._rawDescription?.[lang]} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const QUERY = graphql`
  query ServicePage {
    sanityServicePage {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      data {
        _rawTitle
        _rawTitleBreadcrumb
        _rawTeamTitle
        _rawTeamDescription
        _rawSubtitle
        _rawDescription
        bgImage {
          _rawAlt
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        teamMembers {
          _key
          _rawDescription
          name
          firstname
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        serviceItems {
          _key
          _rawContent
          _rawTitle
          icon
        }
      }
    }
  }
`;

export default ServicePage;

library.add(
  faLeaf,
  faMapMarkedAlt,
  faSunCloud,
  faShoppingBag,
  faHeart,
  faBackpack,
  faBus,
  faBuilding,
  faCalendar,
  faCamera,
  faCampground,
  faCar,
  faMeat,
  faUtensils,
  faGlassChampagne,
  faGlassCheers,
  faHotel,
  faConciergeBell,
  faBed,
  faBedEmpty
);
